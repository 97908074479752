import { combineReducers } from "redux";

import activationReducer from "./activation/reducer";
import {
  accessCell,
  loginCell,
  passwordForgottenCell,
  passwordResetConfirmCell,
  passwordResetVerifyCell,
} from "./cells";

export default combineReducers({
  access: accessCell.reducer,
  activation: activationReducer,
  login: loginCell.reducer,
  passwordForgotten: passwordForgottenCell.reducer,
  passwordResetConfirm: passwordResetConfirmCell.reducer,
  passwordResetVerify: passwordResetVerifyCell.reducer,
});
