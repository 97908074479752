import { createForm } from "async-lifecycle-saga";
import {
  AsyncAction,
  AsyncValue,
  Success,
} from "async-lifecycle-saga/dist/models";
import { put } from "redux-saga/effects";

import { selectBaseUrlContext } from "../../cells";
import { httpOk } from "../../fetch";
import {
  clearTokens,
  clearUsername,
  refreshTokenKey,
  setToken,
  setUsername,
} from "../../token";
import { usersCurrentCell } from "../../users/cells";
import { loginCell } from "../cells";
import { activatePoster, activateVerifyPoster } from "./api";
import { ActivateRequest, ActivateResponse } from "./models";

export const activateCell = createForm({
  api: activatePoster,
  onRefresh: () => "clear",
  path: ["tokens", "activation", "activate"],
});

export function* activateSuccess({
  result,
}: AsyncAction<
  ActivateRequest,
  ActivateResponse,
  AsyncValue<ActivateResponse>
>): Generator {
  if (
    result &&
    result.status === httpOk &&
    (result as Success<ActivateResponse>).body.activationResult === "Success" &&
    (result as Success<ActivateResponse>).body.refresh
  ) {
    const {
      body: {
        refresh,
        user: { emailAddress },
      },
    } = result as Success<ActivateResponse>;
    clearTokens();
    clearUsername();
    yield put({ type: loginCell.events.clear });
    yield put({ type: usersCurrentCell.events.clear });
    setToken(refreshTokenKey, refresh);
    setUsername(emailAddress);
    yield put(usersCurrentCell.require());
    yield put({ type: activateCell.events.clear });
    yield put({ type: activateVerifyCell.events.clear });
  }
}

export const activateVerifyCell = createForm({
  api: activateVerifyPoster,
  context: selectBaseUrlContext,
  onRefresh: () => "clear",
  path: ["tokens", "activation", "verify"],
});
