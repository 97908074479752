import { AsyncListValue, AsyncSingleValue } from "async-lifecycle-saga";

import { Culture } from "../localization";
import Organisation from "../models/Organisation";
import User from "../models/User";
import { ActivateResponse } from "./authentication/activation/models";
import { LoginResponse, TokenModel } from "./authentication/models";

/**
 * General application options for the TIP application.
 */
export interface ApplicationOptions {
  applicationName: string;
  baseUrl: string;
  enableAdBlockerCheck?: boolean;
  enableBookSpot?: boolean;
  enableSkoGrps?: boolean;
  instrumentationKey?: string;
  releaseNotesUrl?: string;
  showTermsOfUse?: boolean;
  showTitles?: boolean;
  tutorialsUrl?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const defaultApplicationOptions: ApplicationOptions = {
  baseUrl: "http://localhost:5000",
  applicationName: "Screenforce TIP (DEVELOPMENT)",
  enableBookSpot: true,
  enableAdBlockerCheck: true,
  showTermsOfUse: true,
  showTitles: true,
  tutorialsUrl: "https://screenforce.nl/tip-tutorials/",
  releaseNotesUrl: "https://screenforce.nl/uitbreiding-tip-release-note/",
  enableSkoGrps: true,
};

declare global {
  interface Window {
    dipApplicationOptions?: ApplicationOptions;
    tipBrowserCulture?: Culture;
  }
}

export interface StoreModel {
  application: {
    options: ApplicationOptions;
  };
  organisations: {
    all: AsyncListValue<Organisation>;
  };
  tokens: {
    access: AsyncSingleValue<TokenModel>;
    activation: {
      activate: AsyncSingleValue<ActivateResponse>;
      verify: AsyncSingleValue<string>;
    };
    login: AsyncSingleValue<LoginResponse>;
    passwordForgotten: AsyncSingleValue<string>;
    passwordResetConfirm: AsyncSingleValue<unknown>;
    passwordResetVerify: AsyncSingleValue<unknown>;
  };
  users: {
    current: AsyncSingleValue<User>;
  };
}
