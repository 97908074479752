import classNames from "classnames";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  inline?: boolean;
  wide?: boolean;
}

const Main = ({ children, inline, wide }: Props): JSX.Element => (
  <div className="main-outer">
    <div className="main-block">
      <div className={classNames("main-inner", { inline, wide })}>
        {children}
        {!inline && <div className="main-stretch" />}
      </div>
    </div>
  </div>
);

export default Main;
