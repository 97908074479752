import { Modal, Typography } from "antd";
import React, { Component, ReactNode } from "react";

interface ErrorBoundaryProps {
  children?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount(): void {
    window.onerror = () => {
      this.setState({ hasError: true });
    };
  }

  componentDidCatch(): void {
    this.setState({ hasError: true });
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      this.setState({ hasError: false });
      Modal.error({
        title: "Error",
        content: (
          <Typography.Paragraph>
            An error has occurred, try to refresh this page.
          </Typography.Paragraph>
        ),
        okText: "Refresh",
        onOk() {
          window.location.reload();
        },
      });
      return null;
    }

    return children ?? null;
  }
}

export default ErrorBoundary;
