import { Typography } from "antd";
import React from "react";

const HeaderTitle = ({
  title,
  parent,
}: {
  title: string;
  parent?: string;
}): JSX.Element => (
  <Typography className="dip-header-title">
    <em>{parent && <>{parent} &raquo; </>}</em>
    <strong>{title}</strong>
  </Typography>
);
export default HeaderTitle;
