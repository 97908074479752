import { Trans } from "@lingui/macro";
import { Divider, Steps } from "antd";
import React from "react";

interface ActivationStepsProps {
  currentStep?: number;
}

const ActivationSteps = ({
  currentStep,
}: ActivationStepsProps): JSX.Element => (
  <>
    <Steps current={currentStep} size="small">
      <Steps.Step title={<Trans>Verify email address</Trans>} />
      <Steps.Step title={<Trans>Set password</Trans>} />
      <Steps.Step title={<Trans>Ready for use</Trans>} />
    </Steps>
    <Divider />
  </>
);

export default ActivationSteps;
