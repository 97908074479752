import React, { ReactElement, ReactNode } from "react";

interface TrunkProps {
  children: ReactNode;
}

const Trunk = ({ children }: TrunkProps): ReactElement => (
  <div className="trunk">{children}</div>
);

export default Trunk;
