import { Alert, Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loginCell } from "../../../store/authentication/cells";
import { LoginRequest } from "../../../store/authentication/models";
import { httpUnauthorized } from "../../../store/fetch";
import { StoreModel } from "../../../store/models";
import { labelCol, wrapperColShallow } from "../../../views/layout/Form";
import Login, { LoginState } from "./Login";

const LoginContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [loginState, setLoginState] = useState<LoginState>("Default");

  /**
   * Determines whether the login request is being processed.
   */
  const isLoading = useSelector(
    ({
      tokens: {
        login: {
          status: { loading },
        },
      },
    }: StoreModel) => loading
  );

  const handleSubmit = useCallback(
    /**
     * Handles the completion of the login form.
     */
    ({ username, password }: LoginRequest): void => {
      dispatch(
        loginCell.require(
          { username, password },
          {
            onFail: ({ status }) => {
              if (status === httpUnauthorized) {
                setLoginState("Invalid");
              } else {
                setLoginState("Error");
              }
            },
          }
        )
      );
    },
    [dispatch]
  );

  return (
    <Form
      form={form}
      labelCol={labelCol}
      name="Login"
      onFinish={handleSubmit}
      wrapperCol={wrapperColShallow}
    >
      <Login form={form} loading={isLoading} state={loginState} />
      <Alert
        message="No account yet?"
        description={
          <>
            Request an account from{" "}
            <a href="mailto:screenforce-mediadogs@twijg-it.nl">Screenforce</a>.
          </>
        }
        type="info"
      />
    </Form>
  );
};

export default LoginContainer;
