import queryString from "query-string";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import PasswordResetConfirmForm from "./PasswordResetConfirmForm";

const ConfirmPasswordResetFormWrapper = (): JSX.Element => {
  const { search } = useLocation();
  const { token, mail } = queryString.parse(search);

  if (!token || !mail) {
    // incorrect uri => redirect to home
    return <Navigate to="/" />;
  }

  return (
    <PasswordResetConfirmForm token={token as string} mail={mail as string} />
  );
};
export default ConfirmPasswordResetFormWrapper;
