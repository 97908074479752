import { createForm } from "async-lifecycle-saga";
import {
  AsyncAction,
  AsyncValue,
  RequestAction,
  Success,
} from "async-lifecycle-saga/dist/models";
import moment from "moment";
import { call, put } from "redux-saga/effects";

import { history } from "../../App";
import { selectBaseUrlContext } from "../cells";
import {
  clearTokens,
  getUsername,
  refreshTokenKey,
  setToken,
  setUsername,
} from "../token";
import { usersCurrentCell } from "../users/cells";
import {
  accessPoster,
  loginPoster,
  passwordForgottenPoster,
  passwordResetConfirmPoster,
  passwordResetVerifyPoster,
} from "./api";
import { LoginRequest, LoginResponse } from "./models";

/* Access */
export const accessCell = createForm({
  api: accessPoster,
  context: selectBaseUrlContext,
  path: ["tokens", "access"],
});

/* Login */
export const loginCell = createForm({
  api: loginPoster,
  path: ["tokens", "login"],
});

// additional sagas
export function* loginPreRequest(
  action: RequestAction<LoginRequest, LoginResponse>
): Generator {
  const currentUsername = (yield call(getUsername) as unknown) as
    | string
    | undefined;
  if (
    currentUsername?.toLowerCase() !== action.payload.username.toLowerCase()
  ) {
    yield call(clearTokens);
  }
  yield call(setUsername, action.payload.username);
}

export function* loginPostRequest(
  action: AsyncAction<LoginRequest, LoginResponse, AsyncValue<LoginResponse>>
): Generator {
  const {
    body: { expires, refreshToken },
  } = action.result as Success<LoginResponse>;
  yield call(setToken, refreshTokenKey, {
    expires: moment.utc(expires).toDate(),
    result: "Success",
    token: refreshToken,
  });
  yield put(
    usersCurrentCell.require({
      onSuccess() {
        history.push("/");
      },
    })
  );
}

/* Logout */
export const logoutEvent = "AUTHENTICATION_LOGOUT";

export function* logout(): Generator {
  yield call(clearTokens);
  yield put({ type: loginCell.events.clear });
  yield put({ type: usersCurrentCell.events.clear });
  history.push("/auth/login");
}

/* Password Forgotten */
export const passwordForgottenCell = createForm({
  api: passwordForgottenPoster,
  context: ({
    application: {
      options: { baseUrl },
    },
  }) => ({ baseUrl }),
  path: ["tokens", "passwordForgotten"],
});

export const passwordResetConfirmCell = createForm({
  api: passwordResetConfirmPoster,
  context: ({
    application: {
      options: { baseUrl },
    },
  }) => ({ baseUrl }),
  path: ["tokens", "passwordResetConfirm"],
});

export function* passwordResetConfirmSuccess(): Generator {
  yield put({ type: passwordResetConfirmCell.events.clear });
  clearTokens();
  yield put({ type: usersCurrentCell.events.clear });
}

export const passwordResetVerifyCell = createForm({
  api: passwordResetVerifyPoster,
  context: ({
    application: {
      options: { baseUrl },
    },
  }) => ({ baseUrl }),
  path: ["tokens", "passwordResetVerify"],
});
