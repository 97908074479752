import React from "react";
import { Route, Routes } from "react-router-dom";

import { auths } from "../../navigation";
import HeaderTitle from "../../views/HeaderTitle";
import HtmlHead from "../../views/HtmlHead";
import Main from "../../views/layout/Main";

const Authenticate = (): JSX.Element => (
  <Main>
    <Routes>
      {auths.map(({ slug, label, catchAll = false, component: Component }) => (
        <Route
          key={slug}
          path={catchAll ? "*" : slug}
          element={
            <div>
              <HtmlHead title={label} />
              <HeaderTitle title={label} />
              <Component />
            </div>
          }
        />
      ))}
    </Routes>
  </Main>
);

export default Authenticate;
