import React, { ReactNode } from "react";

import TipHeader from "../../views/layout/TipHeader";
import UserMenu from "./UserMenu";

interface Props {
  menu: ReactNode;
}

const Header = ({ menu }: Props): JSX.Element => (
  <TipHeader menu={menu} userMenu={<UserMenu />} />
);

export default Header;
