import {
  AsyncResponse,
  toAsyncResponse,
  toAsyncResponseWith,
} from "async-lifecycle-saga";
import moment from "moment";

import { AccessRequest, TokenModel } from "./authentication/models";
import {
  accessTokenKey,
  getToken,
  getUsername,
  refreshTokenKey,
  setToken,
} from "./token";

const jsonConfig: RequestInit = {
  credentials: "same-origin",
};

const jsonHeaders: HeadersInit = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export type HttpStatusCode =
  | 200
  | 201
  | 204
  | 304
  | 401
  | 403
  | 409
  | 410
  | 500;

export const httpOk: HttpStatusCode = 200;
export const httpCreated: HttpStatusCode = 201;
export const httpNoContent: HttpStatusCode = 204;
export const httpNotModified: HttpStatusCode = 304;
export const httpUnauthorized: HttpStatusCode = 401;
export const httpForbidden: HttpStatusCode = 403;
export const httpConflict: HttpStatusCode = 409;
export const httpGone: HttpStatusCode = 410;
export const httpInternalServerError: HttpStatusCode = 500;

type HttpMethod = "get" | "post" | "put" | "delete";

export type MapBodyFunc<T, U> = (body: T) => U;

const fetchWork = <T, U = T>(
  baseUrl: string,
  method: HttpMethod,
  pathAndQuery: string,
  token?: string,
  body?: string | FormData,
  downloadFile?: boolean,
  headersOverride?: HeadersInit,
  map?: MapBodyFunc<T, U>
): Promise<AsyncResponse<U>> => {
  const headersInit = headersOverride ?? jsonHeaders;
  const headers = token
    ? { ...headersInit, Authorization: `Bearer ${token}` }
    : headersInit;
  const initOptions: RequestInit = {
    headers,
    method,
    body,
    ...jsonConfig,
  };

  if (map) {
    return fetch(baseUrl + pathAndQuery, initOptions).then(
      toAsyncResponseWith(map)
    );
  }

  return fetch(baseUrl + pathAndQuery, initOptions).then(toAsyncResponse);
};

const fetcher = async <T, U = T>(
  baseUrl: string,
  method: HttpMethod,
  pathAndQuery: string,
  useAccessToken = true,
  body: string | FormData | undefined = undefined,
  downloadFile: boolean | undefined = undefined,
  headersOverride: HeadersInit | undefined = undefined,
  map: MapBodyFunc<T, U> | undefined = undefined
): Promise<AsyncResponse<U>> => {
  if (useAccessToken) {
    try {
      const accessToken = await getAccessToken(baseUrl);
      return await fetchWork(
        baseUrl,
        method,
        pathAndQuery,
        accessToken,
        body,
        downloadFile,
        headersOverride,
        map
      );
    } catch (reason) {
      return Promise.reject(reason);
    }
  }
  return fetchWork(
    baseUrl,
    method,
    pathAndQuery,
    undefined,
    body,
    downloadFile,
    headersOverride,
    map
  );
};

const getAccessToken = async (baseUrl: string): Promise<string> => {
  const accessToken = getToken(accessTokenKey);
  if (
    accessToken &&
    moment.utc().isSameOrBefore(moment.utc(accessToken.expires))
  ) {
    return accessToken.token;
  }

  const refreshToken = getToken(refreshTokenKey);
  if (
    !refreshToken ||
    (refreshToken && moment.utc().isAfter(moment.utc(refreshToken.expires)))
  ) {
    return Promise.reject(new Error("Not authenticated"));
  }

  const accessRequest: AccessRequest = {
    username: getUsername() || "",
    refreshToken: refreshToken.token,
  };

  const requestInit: RequestInit = {
    headers: jsonHeaders,
    method: "post",
    body: JSON.stringify(accessRequest),
    ...jsonConfig,
  };

  const response = await fetch(`${baseUrl}/api/user/access`, requestInit);
  const responseJson = await response.json();
  const receivedToken: TokenModel = {
    token: responseJson.accessToken,
    result: responseJson.result,
    expires: new Date(responseJson.expires),
  };
  setToken(accessTokenKey, receivedToken);
  return receivedToken
    ? receivedToken.token
    : Promise.reject(new Error("Not authenticated"));
};

export const getter = <T, U = T>(
  baseUrl: string,
  pathAndQuery: string,
  useAccessToken = true,
  map: ((body: T) => U) | undefined = undefined
): Promise<AsyncResponse<U>> =>
  fetcher<T, U>(
    baseUrl,
    "get",
    pathAndQuery,
    useAccessToken,
    undefined,
    undefined,
    undefined,
    map
  );

export const poster = <T, TBody, U = T>(
  baseUrl: string,
  pathAndQuery: string,
  useAccessToken = true,
  body: TBody | null = null,
  headersOverride: HeadersInit | undefined = undefined,
  map: MapBodyFunc<T, U> | undefined = undefined
): Promise<AsyncResponse<U>> => {
  // eslint-disable-next-line no-nested-ternary
  const postBody = body
    ? body instanceof FormData
      ? body
      : JSON.stringify(body)
    : undefined;

  return fetcher<T, U>(
    baseUrl,
    "post",
    pathAndQuery,
    useAccessToken,
    postBody,
    undefined,
    headersOverride,
    map
  );
};

export const putter = <T, TBody, U = T>(
  baseUrl: string,
  pathAndQuery: string,
  useAccessToken = true,
  body: TBody | null = null,
  headersOverride: HeadersInit | undefined = undefined,
  map: MapBodyFunc<T, U> | undefined = undefined
): Promise<AsyncResponse<U>> => {
  // eslint-disable-next-line no-nested-ternary
  const putBody = body
    ? body instanceof FormData
      ? body
      : JSON.stringify(body)
    : undefined;

  return fetcher<T, U>(
    baseUrl,
    "put",
    pathAndQuery,
    useAccessToken,
    putBody,
    undefined,
    headersOverride,
    map
  );
};

export const deleter = <T, U = T>(
  baseUrl: string,
  pathAndQuery: string,
  useAccessToken = true,
  map: MapBodyFunc<T, U> | undefined = undefined
): Promise<AsyncResponse<U>> =>
  fetcher<T, U>(
    baseUrl,
    "delete",
    pathAndQuery,
    useAccessToken,
    undefined,
    undefined,
    undefined,
    map
  );
