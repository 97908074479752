import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface LoginButtonProps {
  children?: ReactNode;
}

const LoginButton = ({ children }: LoginButtonProps): JSX.Element => (
  <span className="login-button">
    <Link to="/auth/login">{children}</Link>
  </span>
);

export default LoginButton;
