import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert, Button, Col, Form, Input, Row, Spin } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { ReactNode, useEffect } from "react";

import { getUsername } from "../../../store/token";
import ForgotPasswordButton from "../../../views/authentication/ForgotPasswordButton";
import CenteredBlock from "../../../views/CenteredBlock";
import { wrapperColNoLabel } from "../../../views/layout/Form";

export type LoginState = "Default" | "Error" | "Invalid";

interface LoginProps {
  form: FormInstance;
  loading: boolean;
  state: LoginState;
}

const Login = ({ form, loading, state }: LoginProps): JSX.Element => {
  const { i18n } = useLingui();
  const username = getUsername();
  const { getFieldsError, isFieldsTouched, setFieldsValue } = form;

  useEffect(() => {
    if (username) {
      setFieldsValue({ username, password: "" });
    } else {
      setFieldsValue({ password: "" });
    }
  }, [setFieldsValue, username]);

  return (
    <Spin spinning={loading}>
      <Row>
        <Col xs={24}>
          <Form.Item
            label={<Trans>Username</Trans>}
            name="username"
            rules={[
              { required: true, message: i18n._(t`Username is required.`) },
            ]}
          >
            <Input
              autoFocus
              prefix={<UserOutlined />}
              placeholder={i18n._(t`Username`)}
            />
          </Form.Item>
          <Form.Item
            label={<Trans>Password</Trans>}
            name="password"
            rules={[
              { required: true, message: i18n._(t`Password is required.`) },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={i18n._(t`Password`)}
            />
          </Form.Item>
          <Form.Item wrapperCol={wrapperColNoLabel} shouldUpdate>
            {(): ReactNode => (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !isFieldsTouched(true) ||
                    getFieldsError().filter(({ errors }) => errors.length)
                      .length > 0
                  }
                >
                  <Trans>Login</Trans>
                </Button>

                <ForgotPasswordButton />
              </>
            )}
          </Form.Item>
        </Col>
      </Row>
      {state === "Invalid" && (
        <CenteredBlock>
          <Alert
            type="warning"
            message={<Trans>Username or password is incorrect.</Trans>}
            showIcon
          />
        </CenteredBlock>
      )}
      {state === "Error" && (
        <CenteredBlock>
          <Alert
            type="error"
            message={<Trans>Something went wrong.</Trans>}
            showIcon
          />
        </CenteredBlock>
      )}
    </Spin>
  );
};

export default Login;
