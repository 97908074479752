import { t } from "@lingui/macro";

import Activation from "./components/authentication/activation/Activation";
import LoginContainer from "./components/authentication/login/LoginContainer";
import PasswordForgottenForm from "./components/authentication/passwordForgotten/PasswordForgottenForm";
import PasswordResetConfirmFormWrapper from "./components/authentication/passwordReset/PasswordResetConfirmFormWrapper";
import { SubModule } from "./models/Authorization";

interface NavigationSource {
  slug: string;
  label: string;
  path?: string;
  subModule?: SubModule;
}

export interface NavigationItem {
  path: string;
  label: string;
  parent?: string;
  subModule?: SubModule;
}

export const aboutLabel = t`About`;
export const adminLabel = t`Administration`;
export const apiLabel = t`B2B API docs`;
export const organisationsLabel = t`Organisations`;

const abouts: NavigationSource[] = [
  { slug: "concepts", label: t`General concepts` },
  { slug: "entities", label: t`Entities and relations` },
  { slug: "authentication", label: t`Authentication` },
  { slug: "identification", label: t`Identification by Pseudo ID` },
  { slug: "errors", label: t`Error handling` },
  { slug: "contribute", label: t`Contributing`, subModule: "Contribution" },
];

const apis: NavigationSource[] = [
  { slug: "core", label: t`Core` },
  { slug: "overview", label: t`All functions`, path: "about" },
  { slug: "beta", label: t`Beta`, path: "about" },
  { slug: "rest", label: t`REST`, path: "about", subModule: "Contribution" },
  { slug: "adalliance", label: t`AdAlliance` },
  { slug: "ster", label: t`Ster` },
  { slug: "talpa", label: t`Talpa` },
  { slug: "audiences", label: t`Audiences`, path: "about" },
];

const admin: NavigationSource[] = [
  {
    slug: "organisations",
    label: t`Organisations`,
    subModule: "Organizations",
  },
];

export const auths: (NavigationSource & {
  catchAll?: boolean;
  component: React.FunctionComponent;
})[] = [
  {
    slug: "activate",
    label: t`Activate`,
    component: Activation,
  },
  {
    slug: "password/forgot",
    label: t`Password reset`,
    component: PasswordForgottenForm,
  },
  {
    slug: "password/reset",
    label: t`Set password`,
    component: PasswordResetConfirmFormWrapper,
  },
  {
    slug: "login",
    label: t`Login`,
    component: LoginContainer,
    catchAll: true,
  },
];

const nav =
  (defaultPath: string, parent?: string) =>
  ({
    slug,
    label,
    path = defaultPath,
    subModule,
  }: NavigationSource): NavigationItem => ({
    path: `/${path}/${slug}`,
    label,
    parent,
    subModule,
  });

const navigation: NavigationItem[] = abouts
  .map(nav("about", aboutLabel))
  .concat(apis.map(nav("api", apiLabel)))
  .concat(admin.map(nav("admin", adminLabel)))
  .concat(auths.map(nav("auth")));
export default navigation;
