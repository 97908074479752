import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import audiences from "../assets/content/about/audiences.md";
import authentication from "../assets/content/about/authentication.md";
import beta from "../assets/content/about/beta.md";
import concepts from "../assets/content/about/concepts.md";
import contribute from "../assets/content/about/contribute.md";
import entities from "../assets/content/about/entities.md";
import erd from "../assets/content/about/erd.jpg";
import errors from "../assets/content/about/errors.md";
import identification from "../assets/content/about/identification.md";
import oauth from "../assets/content/about/oauth.png";
import overview from "../assets/content/about/overview.md";
import pseudoid from "../assets/content/about/pseudoid.svg";
import rest from "../assets/content/about/rest.md";
import navigation from "../navigation";
import HeaderTitle from "../views/HeaderTitle";
import HtmlHead from "../views/HtmlHead";
import Main from "../views/layout/Main";
import Markdown from "../views/Markdown";

const abouts: { [key: string]: string } = {
  audiences,
  authentication,
  beta,
  concepts,
  contribute,
  entities,
  errors,
  identification,
  overview,
  rest,
};

const cache: { [key: string]: Promise<string> } = {};
const load = (key: string): Promise<string> => {
  if (!(key in cache)) {
    cache[key] = fetch(abouts[key]).then((response) => response.text());
  }

  return cache[key];
};

const imgs: { [uri: string]: string } = {
  "erd.jpg": erd,
  "oauth.png": oauth,
  "pseudoid.svg": pseudoid,
};

const About = (): JSX.Element => {
  const [text, setText] = useState("");
  const { subject } = useParams() as { subject: string };
  const { pathname } = useLocation();

  useEffect((): void => {
    setText("");
    load(subject).then(setText);
  }, [subject]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { label, parent } = navigation.find((a) => a.path === pathname)!;

  return (
    <Main>
      <HtmlHead title={label} />
      <HeaderTitle title={label} parent={parent} />
      {text ? <Markdown imgs={imgs}>{text}</Markdown> : <Spin />}
    </Main>
  );
};

export default About;
