import { Typography } from "antd";
import React from "react";

const DefaultFooter = (): JSX.Element => (
  <Typography.Text disabled>
    &copy; Screenforce {new Date().getFullYear()}
  </Typography.Text>
);

export default DefaultFooter;
