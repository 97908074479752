import React from "react";
import { Link } from "react-router-dom";

const ForgotPasswordButton = (): JSX.Element => (
  <div className="login-form-forgot">
    <Link to="/auth/password/forgot">Password lost?</Link>
  </div>
);

export default ForgotPasswordButton;
