import { Trans, t } from "@lingui/macro";
import { Spin, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  passwordResetConfirmCell,
  passwordResetVerifyCell,
} from "../../../store/authentication/cells";
import { httpConflict, httpInternalServerError } from "../../../store/fetch";
import { StoreModel } from "../../../store/models";
import { clearUsername } from "../../../store/token";
import PasswordForm, { PasswordMinimalValues } from "../PasswordForm";
import PasswordResetVerifyError from "./PasswordResetVerifyError";
import PasswordResetVerifyInvalid from "./PasswordResetVerifyInvalid";

const emptyString = "";
type VerificationStateType = "Valid" | "Invalid" | "Error";

const PasswordResetConfirmForm = ({
  token,
  mail,
}: {
  token: string;
  mail: string;
}): JSX.Element | null => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [verificationState, setVerificationState] =
    useState<VerificationStateType>("Valid");

  const dispatch = useDispatch();
  const handleSuccess = useCallback(
    (
      { password, passwordRepeat }: PasswordMinimalValues | undefined = {
        password: emptyString,
        passwordRepeat: emptyString,
      }
    ) => {
      if (password && password === passwordRepeat) {
        dispatch(
          passwordResetConfirmCell.require(
            { newPassword: password, resetToken: token, username: mail },
            {
              onFail({ detail }) {
                setIsConfirmed(false);
                message.error(
                  t`Your password could not be reset. An unexpected error occurred. Details: ${detail}`
                );
              },
              onSuccess() {
                setIsConfirmed(true);
                message.success(
                  t`Your password has been reset. From now on, you can log in with your new password.`
                );
              },
            }
          )
        );
      }
    },
    [dispatch, mail, token]
  );
  const isLoading = useSelector(
    ({
      tokens: {
        passwordResetConfirm: {
          status: { loading },
        },
      },
    }: StoreModel) => loading
  );

  useEffect(() => {
    clearUsername();
    dispatch(
      passwordResetVerifyCell.require(
        {
          emailAddress: mail,
          token,
        },
        {
          onFail: ({ status }) => {
            switch (status) {
              case httpConflict:
                setVerificationState("Invalid");
                break;
              case httpInternalServerError:
              default:
                setVerificationState("Error");
                break;
            }
          },
        }
      )
    );
  }, [dispatch, mail, token]);

  switch (verificationState) {
    case "Valid":
      return (
        <Spin spinning={isLoading}>
          <PasswordForm
            buttonLabel={<Trans>Reset</Trans>}
            name="PasswordResetConfirm"
            onSuccess={handleSuccess}
            showLoginButton={isConfirmed}
          />
        </Spin>
      );
    case "Invalid":
      return <PasswordResetVerifyInvalid />;
    case "Error":
      return <PasswordResetVerifyError />;
    default:
      return null;
  }
};
export default PasswordResetConfirmForm;
