import { Tooltip } from "antd";
import React from "react";

const Ellipsis = ({
  text,
  long,
}: {
  text: string;
  long?: string;
}): JSX.Element => (
  <Tooltip className="ellipsis" title={long || text}>
    <span>{text}</span>
  </Tooltip>
);

export default Ellipsis;
