import React from "react";
import { Helmet } from "react-helmet";

interface HtmlHeadProps {
  title: string;
}

const HtmlHead = ({ title }: HtmlHeadProps): JSX.Element => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
);

export default HtmlHead;
