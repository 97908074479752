import { Trans } from "@lingui/macro";
import React from "react";

import PasswordForm from "../PasswordForm";
import { ActivationPasswordStepModel, ActivationStepProps } from "./models";

const ActivationPasswordStep = ({
  onNext,
}: ActivationStepProps<ActivationPasswordStepModel>): JSX.Element => (
  <PasswordForm
    buttonLabel={<Trans>Next</Trans>}
    name="PasswordActivation"
    onSuccess={onNext}
    showLoginButton={false}
  />
);

export default ActivationPasswordStep;
