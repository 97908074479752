import { StoreModel } from "./models";

export interface BaseUrlContext {
  baseUrl: string;
}

export const selectBaseUrlContext = ({
  application: {
    options: { baseUrl },
  },
}: StoreModel): BaseUrlContext => ({ baseUrl });
