import { LockOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { ReactElement, ReactNode, useCallback } from "react";

import LoginButton from "../../views/authentication/LoginButton";
import {
  labelCol,
  wrapperColNoLabel,
  wrapperColShallow,
} from "../../views/layout/Form";

export const passwordRequiredMessage = t`Password is required.`;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g;
export const passwordRegexMessage = t`Password must be at least 8 characters, contain at least 1 number and upper and lower case letters`;
export const passwordIdenticalMessage = t`Passwords must be identical.`;

interface PasswordFormComponentProps<V> {
  buttonLabel: ReactNode;
  name: string;
  onSuccess: (values?: V) => void;
  showLoginButton: boolean;
}

export interface PasswordMinimalValues {
  password: string;
  passwordRepeat: string;
}

const PasswordForm = <T extends PasswordMinimalValues>({
  buttonLabel,
  name,
  onSuccess,
  showLoginButton,
}: PasswordFormComponentProps<T>): JSX.Element => {
  const { i18n } = useLingui();
  const [form] = useForm();

  const handleFinish = useCallback(
    (values: T) => {
      onSuccess(values);
      form.resetFields();
    },
    [form, onSuccess]
  );

  const validatePasswordRepeatEqual = useCallback(
    (_rule: unknown, value: string) => {
      const password = form.getFieldValue("password");
      if (value === password) {
        return Promise.resolve();
      }
      return Promise.reject(i18n._(passwordIdenticalMessage));
    },
    [form, i18n]
  );

  return (
    <Form
      form={form}
      labelCol={labelCol}
      name={name}
      onFinish={handleFinish}
      wrapperCol={wrapperColShallow}
    >
      <Form.Item
        label={<Trans>New password</Trans>}
        name="password"
        rules={[
          { required: true, message: i18n._(passwordRequiredMessage) },
          { pattern: passwordRegex, message: i18n._(passwordRegexMessage) },
        ]}
      >
        <Input.Password
          autoFocus
          prefix={<LockOutlined />}
          placeholder={i18n._(t`Password`)}
        />
      </Form.Item>
      <Form.Item
        label={<Trans>New password (again)</Trans>}
        name="passwordRepeat"
        rules={[
          { required: true, message: i18n._(passwordRequiredMessage) },
          { validator: validatePasswordRepeatEqual },
        ]}
      >
        <Input.Password
          placeholder={i18n._(t`Password (again)`)}
          prefix={<LockOutlined />}
        />
      </Form.Item>
      <Form.Item shouldUpdate wrapperCol={wrapperColNoLabel}>
        {(): ReactElement => (
          <>
            <Button
              disabled={
                !form.isFieldsTouched(true) ||
                form.getFieldsError().some(({ errors }) => errors.length > 0)
              }
              htmlType="submit"
              type="primary"
            >
              {buttonLabel}
            </Button>
            {showLoginButton && (
              <LoginButton>
                <Trans>To the login form</Trans>
              </LoginButton>
            )}
          </>
        )}
      </Form.Item>
    </Form>
  );
};

export default PasswordForm;
