import { UserOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert, Button, Col, Form, Input, Row, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { passwordForgottenCell } from "../../../store/authentication/cells";
import { httpConflict } from "../../../store/fetch";
import { StoreModel } from "../../../store/models";
import { getUsername } from "../../../store/token";
import {
  labelCol,
  wrapperColNoLabel,
  wrapperColShallow,
} from "../../../views/layout/Form";

type RequestResetState = "Default" | "Invalid" | "Error";

const ForgotPasswordForm = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = useForm();
  const { getFieldValue, isFieldTouched, setFieldsValue } = form;
  const [requestResetState, setRequestResetState] =
    useState<RequestResetState>("Default");
  const { i18n } = useLingui();

  const loading = useSelector(
    ({
      tokens: {
        passwordForgotten: { status },
      },
    }: StoreModel) => status.loading
  );

  const handleFinish = useCallback(
    ({ username }: { username: string }): void => {
      dispatch(
        passwordForgottenCell.require(
          { username },
          {
            onFail: ({ status }) => {
              switch (status) {
                case httpConflict:
                  setRequestResetState("Invalid");
                  break;
                default:
                  setRequestResetState("Error");
                  break;
              }
            },
            onSuccess: () => {
              setRequestResetState("Default");
              const successMessage = i18n._(t`Mail sent`);
              message.success(successMessage);
              navigate("/auth/login");
            },
          }
        )
      );
    },
    [dispatch, navigate, i18n]
  );

  useEffect(() => {
    const username = getUsername();
    if (username) {
      setFieldsValue({
        username,
      });
    }
  }, [setFieldsValue]);

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            labelCol={labelCol}
            name="ForgotPasswordForm"
            onFinish={handleFinish}
            wrapperCol={wrapperColShallow}
          >
            <Form.Item
              label={<Trans>Username</Trans>}
              name="username"
              rules={[
                { required: true, message: i18n._(t`Username is required.`) },
              ]}
              help={
                <Trans>
                  Enter your username. If you are a user of this application,
                  you will receive an email with a link to reset your password.
                </Trans>
              }
            >
              <Input
                autoFocus
                prefix={<UserOutlined />}
                placeholder={i18n._(t`Username`)}
              />
            </Form.Item>
            <Form.Item shouldUpdate wrapperCol={wrapperColNoLabel}>
              {(): ReactElement => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !getFieldValue("username") && !isFieldTouched("username")
                  }
                >
                  <Trans>Reset password</Trans>
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
      {requestResetState === "Invalid" && (
        <Row>
          <Col span={24}>
            <Alert
              type="warning"
              message={<Trans>Invalid e-mail</Trans>}
              showIcon
            />
          </Col>
        </Row>
      )}
      {requestResetState === "Error" && (
        <Row>
          <Col span={24}>
            <Alert
              type="error"
              message={<Trans>Something went wrong.</Trans>}
              showIcon
            />
          </Col>
        </Row>
      )}
    </Spin>
  );
};

export default ForgotPasswordForm;
