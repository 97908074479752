import moment from "moment";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route } from "react-router-dom";

import { SubModule } from "../../models/Authorization";
import { b2bDocumentationRoleName } from "../../models/UserRole";
import { StoreModel } from "../../store/models";
import { getToken, refreshTokenKey } from "../../store/token";

export const validate = (): boolean => {
  const refreshToken = getToken(refreshTokenKey);
  return (
    refreshToken !== undefined &&
    moment.utc(refreshToken.expires).toDate().valueOf() >
      moment.utc().toDate().valueOf() &&
    Boolean(refreshToken.token)
  );
};
export const useSubModuleAuthorizationService = (): ((
  requiredSubModule: SubModule
) => boolean) => {
  const currentUser = useSelector(
    ({
      users: {
        current: { value },
      },
    }: StoreModel) => value
  );
  return (requiredSubModule: SubModule) =>
    Boolean(
      currentUser &&
        currentUser.authorizations
          ?.find((auth) => auth.role === b2bDocumentationRoleName)
          ?.subModules?.some((sm) => sm === requiredSubModule)
    );
};

export const PrivateElement = ({
  element,
}: {
  element: ReactNode;
}): JSX.Element =>
  // eslint-disable-next-line react/jsx-no-useless-fragment
  validate() ? <>{element}</> : <Navigate to="/auth/login" replace />;

const protect = (path: string, element: ReactNode): JSX.Element => (
  <Route path={path} element={<PrivateElement element={element} />} />
);

export default protect;
