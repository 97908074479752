import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Table, Typography } from "antd";
import { ColumnProps } from "antd/lib/table";
import React, { ReactNode, memo } from "react";

import Organisation from "../../models/Organisation";
import propertyOf from "../../utils/properties";
import Ellipsis from "../../views/Ellipsis";

const codeField = propertyOf<Organisation>("code");
const nameField = propertyOf<Organisation>("name");

interface OrganisationsTableProps {
  loading?: boolean;
  organisations: Organisation[];
}

const OrganisationsTable = ({
  loading,
  organisations,
}: OrganisationsTableProps) => {
  const { i18n } = useLingui();

  const columns: ColumnProps<Organisation>[] = [
    {
      title: i18n._(t`Code`),
      dataIndex: codeField,
      key: codeField,
      width: 100,
      render: (_text, { code }): ReactNode => (
        <Typography.Text>{code}</Typography.Text>
      ),
    },
    {
      title: i18n._(t`Name`),
      dataIndex: nameField,
      key: nameField,
      width: 100,
      ellipsis: true,
      render: (_text, { name }): ReactNode => <Ellipsis text={name} />,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={organisations}
      loading={loading}
      pagination={false}
      rowKey={(organisation) => organisation.id}
    />
  );
};

export default memo(OrganisationsTable);
