import { combineReducers } from "redux";

import applicationReducer from "../application/reducer";
import tokensReducer from "../authentication/reducer";
import { StoreModel } from "../models";
import organisationsReducer from "../organisations/reducer";
import usersReducer from "../users/reducer";

const appReducer = combineReducers<StoreModel>({
  application: applicationReducer,
  organisations: organisationsReducer,
  tokens: tokensReducer,
  users: usersReducer,
});

export default appReducer;
