import { Trans } from "@lingui/macro";
import { Col, Row, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const ResetVerifyInvalid = (): JSX.Element => (
  <Row>
    <Col span={12} offset={6}>
      <Typography.Paragraph>
        <Trans>
          The URL you used is no longer valid. Try to{" "}
          <Link to="/auth/password/forgot">reset your password</Link> again.
        </Trans>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Trans>
          Go back to the <Link to="/auth/login">login form</Link>.
        </Trans>
      </Typography.Paragraph>
    </Col>
  </Row>
);

export default ResetVerifyInvalid;
