import { PoweroffOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import { Menu } from "antd";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { logoutEvent } from "../../store/authentication/cells";

const logOffKey = "LogOff";

const menuKeys: string[] = [logOffKey];

const UserContextMenu =
  /**
   * Renders a component that displays a user account's context menu.
   * @constructor Initializes a new instance of UserContextMenu.
   */
  (): JSX.Element => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const handleLogOffClick = useCallback(
      /**
       * Logs the user off.
       */
      (): void => {
        dispatch({ type: logoutEvent });
      },
      [dispatch]
    );

    const selectedKeys = useMemo(() => {
      const navigationItem = menuKeys.find((mk) => pathname === mk);
      return navigationItem ? [navigationItem] : [];
    }, [pathname]);

    return (
      <Menu selectedKeys={selectedKeys}>
        <Menu.Item key={logOffKey} onClick={handleLogOffClick}>
          <PoweroffOutlined />
          <Trans>Log out</Trans>
        </Menu.Item>
      </Menu>
    );
  };

export default UserContextMenu;
