import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Organisation, { organisationsEmpty } from "../../models/Organisation";
import navigation from "../../navigation";
import { StoreModel } from "../../store/models";
import { organizationsAllCell } from "../../store/organisations/cells";
import HeaderTitle from "../../views/HeaderTitle";
import HtmlHead from "../../views/HtmlHead";
import Main from "../../views/layout/Main";
import {
  useSubModuleAuthorizationService,
  validate,
} from "../authentication/PrivateRoute";
import OrganisationsTable from "./OrganisationsTable";

const renderTable = (value?: Organisation[]) => (
  <OrganisationsTable organisations={value || organisationsEmpty} />
);

const renderLoading = (loading: boolean) =>
  loading ? (_value?: Organisation[]) => <Spin /> : renderTable;

const renderAuthorized = (isAuthorized: boolean) =>
  isAuthorized
    ? renderLoading
    : (_loading: boolean) => (_value?: Organisation[]) => null;

const Organisations = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    status: { loading },
    value,
  } = useSelector(({ organisations: { all } }: StoreModel) => all);
  const { pathname } = useLocation();
  const subModuleAuthorize = useSubModuleAuthorizationService();

  const hasCurrentUser = validate();
  const hasOrganisations = subModuleAuthorize("Organizations");

  useEffect(() => {
    if (hasCurrentUser && hasOrganisations) {
      dispatch(organizationsAllCell.require());
    }
  }, [dispatch, hasCurrentUser, hasOrganisations]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { label, parent } = navigation.find((a) => a.path === pathname)!;

  return (
    <Main>
      <HtmlHead title={label} />
      <HeaderTitle title={label} parent={parent} />
      {renderAuthorized(hasCurrentUser && hasOrganisations)(loading)(value)}
    </Main>
  );
};

export default Organisations;
