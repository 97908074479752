import { Action, Reducer, applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import { StoreModel } from "./models";
import appReducer from "./reducers/appReducer";
import rootSaga from "./sagas";

const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const sagaMiddleware = createSagaMiddleware();

const rootReducer: Reducer<StoreModel, Action<string>> = (
  state: StoreModel | undefined,
  action: Action<string>
) => appReducer(state, action);

const store = createStore<
  StoreModel,
  Action<string>,
  Record<string, unknown>,
  Record<string, unknown>
>(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export default store;
