import { Col, Row } from "antd";
import React, { ReactNode } from "react";

const CenteredBlock = ({ children }: { children: ReactNode }): JSX.Element => (
  <Row gutter={[0, 16]}>
    <Col span={14} offset={5}>
      {children}
    </Col>
  </Row>
);

export default CenteredBlock;
