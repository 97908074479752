import { i18n } from "@lingui/core";
import { Layout, Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";

import navigation, {
  NavigationItem,
  aboutLabel,
  adminLabel,
  apiLabel,
  organisationsLabel,
} from "../navigation";
import DefaultFooter from "../views/layout/DefaultFooter";
import Trunk from "../views/layout/Trunk";
import About from "./About";
import Authenticate from "./authentication/Authenticate";
import protect, {
  useSubModuleAuthorizationService,
  validate,
} from "./authentication/PrivateRoute";
import Header from "./navigation/Header";
import Organisations from "./organisations/Organisations";
import Swagger from "./Swagger";

const AppLayout = (): JSX.Element => {
  const location = useLocation();
  const hasCurrentUser = validate();
  const subModuleAuthorize = useSubModuleAuthorizationService();
  const organisations = navigation.find((nav) => nav.parent === adminLabel);

  const filterNavigation =
    (parentLabel: string) => (navigationItem: NavigationItem) =>
      navigationItem.parent === parentLabel &&
      (!navigationItem.subModule ||
        subModuleAuthorize(navigationItem.subModule));

  return (
    <Layout>
      <Layout.Header>
        <Trunk>
          <Header
            menu={
              <Menu
                mode="horizontal"
                selectedKeys={[location.pathname]}
                theme="light"
              >
                {hasCurrentUser && (
                  <SubMenu key="about" title={i18n._(aboutLabel)}>
                    {navigation
                      .filter(filterNavigation(aboutLabel))
                      .map((about) => (
                        <Menu.Item key={about.path}>
                          <Link to={about.path}>{i18n._(about.label)}</Link>
                        </Menu.Item>
                      ))}
                  </SubMenu>
                )}
                {hasCurrentUser && (
                  <SubMenu key="api" title={i18n._(apiLabel)}>
                    {navigation
                      .filter(filterNavigation(apiLabel))
                      .map((api) => (
                        <Menu.Item key={api.path}>
                          <Link to={api.path}>{i18n._(api.label)}</Link>
                        </Menu.Item>
                      ))}
                  </SubMenu>
                )}
                {hasCurrentUser &&
                  subModuleAuthorize("Organizations") &&
                  organisations && (
                    <SubMenu key="admin" title={i18n._(adminLabel)}>
                      {navigation
                        .filter(filterNavigation(adminLabel))
                        .map((api) => (
                          <Menu.Item key={organisationsLabel}>
                            <Link to={api.path}>{i18n._(api.label)}</Link>
                          </Menu.Item>
                        ))}
                    </SubMenu>
                  )}
              </Menu>
            }
          />
        </Trunk>
      </Layout.Header>
      <Layout.Content>
        <Trunk>
          <Routes>
            <Route path="auth/*" element={<Authenticate />} />
            {protect("about/:subject", <About />)}
            {protect("api/:subject", <Swagger />)}
            {protect("admin/organisations", <Organisations />)}
            <Route
              path="*"
              element={<Navigate to="/about/concepts" replace />}
            />
          </Routes>
        </Trunk>
      </Layout.Content>
      <Layout.Footer id="tip-footer">
        <Trunk>
          <DefaultFooter />
        </Trunk>
      </Layout.Footer>
    </Layout>
  );
};

export default AppLayout;
