import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Alert, message } from "antd";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  activateCell,
  activateVerifyCell,
} from "../../../store/authentication/activation/cells";
import {
  httpGone,
  httpInternalServerError,
  httpUnauthorized,
} from "../../../store/fetch";
import { clearTokens, clearUsername } from "../../../store/token";
import { usersCurrentCell } from "../../../store/users/cells";
import ActivationPasswordStep from "./ActivationPasswordStep";
import ActivationReady from "./ActivationReady";
import ActivationSteps from "./ActivationSteps";
import { ActivationPasswordStepModel } from "./models";

type ActivationState = "Default" | "Invalid" | "Error";

const Activation = (): JSX.Element => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { token, mail } = queryString.parse(search);

  const [activationState, setActivationState] =
    useState<ActivationState>("Default");
  const [currentStep, setCurrentStep] = useState(0);

  const handleFinishStep = useCallback(
    (values: unknown) => {
      const { password } = values as ActivationPasswordStepModel;
      dispatch(
        activateCell.require(
          {
            emailAddress: mail as string,
            password,
            activateToken: token as string,
          },
          {
            onFail() {
              message.error(i18n._(t`User activation failed.`));
            },
            onSuccess({ body: { activationResult } }) {
              if (activationResult !== "Success") {
                message.warn(i18n._(t`A problem occurred during activation.`));
                return;
              }
              const nextStep = currentStep + 1;
              setCurrentStep(nextStep);
            },
          }
        )
      );
    },
    [currentStep, dispatch, i18n, mail, token]
  );

  useEffect(() => {
    if (!token || !mail) {
      setActivationState("Error");
      return;
    }

    clearUsername();
    clearTokens();
    dispatch({ type: usersCurrentCell.events.clear });
    dispatch(
      activateVerifyCell.require(
        {
          emailAddress: mail as string,
          token: token as string,
        },
        {
          onFail({ status }) {
            switch (status) {
              case httpGone:
                setActivationState("Invalid");
                setCurrentStep(0);
                break;
              case httpUnauthorized:
              case httpInternalServerError:
              default:
                setActivationState("Error");
                setCurrentStep(0);
                break;
            }
          },
          onSuccess() {
            setActivationState("Default");
            setCurrentStep(1);
          },
        }
      )
    );
  }, [dispatch, mail, token]);

  return (
    <>
      <ActivationSteps currentStep={currentStep} />
      {activationState === "Invalid" && (
        <Alert
          description={
            <Trans>
              This link has expired. A new link will be sent to you by email.
            </Trans>
          }
          message={<Trans>Invalid</Trans>}
          showIcon
          type="warning"
        />
      )}
      {activationState === "Error" && (
        <Alert
          description={<Trans>An unknown error has occurred.</Trans>}
          message={<Trans>Error</Trans>}
          showIcon
          type="error"
        />
      )}
      {currentStep === 1 && (
        <ActivationPasswordStep onNext={handleFinishStep} />
      )}
      {currentStep === 2 && <ActivationReady />}
    </>
  );
};

export default Activation;
