import { t } from "@lingui/macro";

import { Operator } from "./Operator";

export interface OrganisationLogin {
  operator: Operator;
}

export type OrganisationType =
  | "Other"
  | "Advertiser"
  | "MediaAgency"
  | "ProductionCompany";

export const organisationTypeLabels: {
  [organisationType in OrganisationType]: string;
} = {
  Other: t`Overig`,
  Advertiser: t`Advertiser`,
  MediaAgency: t`Media agency`,
  ProductionCompany: t`Production company`,
};

export default interface Organisation {
  id: string;
  name: string;
  code: string;
  logins: OrganisationLogin[];
  isKnownByOperators: boolean;
  type: OrganisationType;
}

export const organisationsEmpty: Organisation[] = [];
