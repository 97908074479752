import { AsyncResponse } from "async-lifecycle-saga";

import User from "../../models/User";
import { BaseUrlContext } from "../cells";
import { getter, poster } from "../fetch";
import {
  AccessRequest,
  LoginRequest,
  LoginResponse,
  PasswordForgottenRequest,
  PasswordResetConfirmRequest,
  PasswordResetVerifyRequest,
  TokenModel,
} from "./models";

const emptyString = "";

const dateifyTokenModel = ({
  expires,
  result,
  token,
}: TokenModel): TokenModel => ({
  expires: new Date(expires),
  result,
  token,
});

export const accessPoster = (
  request: AccessRequest,
  { baseUrl }: BaseUrlContext
): Promise<AsyncResponse<TokenModel>> =>
  poster<TokenModel, AccessRequest>(
    baseUrl,
    "/api/user/access",
    false,
    request,
    undefined,
    dateifyTokenModel
  );

export const getCurrentUser = (
  _request: void,
  { baseUrl }: BaseUrlContext
): Promise<AsyncResponse<User>> => getter<User>(baseUrl, "/api/user");

export const loginPoster = (
  request: LoginRequest
): Promise<AsyncResponse<LoginResponse>> =>
  poster<LoginResponse, LoginRequest>(
    emptyString,
    "/api/user/login",
    false,
    request
  );

export const passwordForgottenPoster = (
  request: PasswordForgottenRequest,
  { baseUrl }: BaseUrlContext
): Promise<AsyncResponse<string>> =>
  poster<string, PasswordForgottenRequest>(
    baseUrl,
    "/api/user/password/reset",
    false,
    request
  );

export const passwordResetConfirmPoster = (
  request: PasswordResetConfirmRequest,
  { baseUrl }: BaseUrlContext
): Promise<AsyncResponse<unknown>> =>
  poster<unknown, PasswordResetConfirmRequest>(
    baseUrl,
    "/api/user/password/confirm",
    false,
    request
  );

export const passwordResetVerifyPoster = (
  request: PasswordResetVerifyRequest,
  { baseUrl }: BaseUrlContext
): Promise<AsyncResponse<unknown>> =>
  poster<unknown, PasswordResetVerifyRequest>(
    baseUrl,
    "/api/user/password/verify",
    false,
    request
  );
