import { createSingle } from "async-lifecycle-saga";

import Organisation from "../../models/Organisation";
import { BaseUrlContext } from "../cells";
import { StoreModel } from "../models";
import { getOrganisationsAll } from "./api";

// eslint-disable-next-line import/prefer-default-export
export const organizationsAllCell = createSingle<
  Organisation[],
  StoreModel,
  BaseUrlContext
>({
  context: ({
    application: {
      options: { baseUrl },
    },
  }) => ({
    baseUrl,
  }),
  path: ["organisations", "all"],
  require: { api: getOrganisationsAll },
});
