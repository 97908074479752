import { all, takeEvery } from "redux-saga/effects";

import {
  activateCell,
  activateSuccess,
  activateVerifyCell,
} from "./authentication/activation/cells";
import {
  accessCell,
  loginCell,
  loginPostRequest,
  loginPreRequest,
  logout,
  logoutEvent,
  passwordForgottenCell,
  passwordResetConfirmCell,
  passwordResetConfirmSuccess,
  passwordResetVerifyCell,
} from "./authentication/cells";
import { organizationsAllCell } from "./organisations/cells";
import { usersCurrentCell } from "./users/cells";

export default sagas;

function* sagas(): Generator {
  const tipSagas = [
    ...accessCell.sagas,
    ...activateCell.sagas,
    takeEvery(activateCell.events.success, activateSuccess),
    ...activateVerifyCell.sagas,
    ...loginCell.sagas,
    takeEvery(loginCell.events.require, loginPreRequest),
    takeEvery(loginCell.events.success, loginPostRequest),
    takeEvery(logoutEvent, logout),
    ...organizationsAllCell.sagas,
    ...passwordForgottenCell.sagas,
    ...passwordResetConfirmCell.sagas,
    takeEvery(
      passwordResetConfirmCell.events.success,
      passwordResetConfirmSuccess
    ),
    ...passwordResetVerifyCell.sagas,
    ...usersCurrentCell.sagas,
  ];

  yield all(tipSagas);
}
