import { UserOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Avatar, Dropdown, Tooltip } from "antd";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from "history";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from "react-router";

import { StoreModel } from "../../store/models";
import { usersCurrentCell } from "../../store/users/cells";
import { getGravatarUrl } from "../../utils/gravatar";
import UserContextMenu from "../user/UserContextMenu";

const isAuthLocation = (location: Location): boolean =>
  location.pathname.includes("/auth/");

const UserMenu = (): JSX.Element | null => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const location = useLocation();
  const locationIsAuth = isAuthLocation(location);

  const user = useSelector(
    ({
      users: {
        current: { value },
      },
    }: StoreModel) => value
  );

  useEffect(() => {
    if (!user && !locationIsAuth) {
      dispatch(usersCurrentCell.require());
    }
  }, [dispatch, locationIsAuth, user]);

  return user && !locationIsAuth ? (
    <Dropdown
      overlay={<UserContextMenu />}
      placement="bottom"
      trigger={["click"]}
    >
      <Tooltip title={i18n._(t`Logged in as: ${user.name}`)} placement="left">
        <Avatar
          icon={<UserOutlined />}
          size="large"
          shape="square"
          src={getGravatarUrl(user.emailAddress)}
        />
      </Tooltip>
    </Dropdown>
  ) : null;
};

export default UserMenu;
