import { Trans } from "@lingui/macro";
import { Col, Row, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const ResetVerifyError = (): JSX.Element => (
  <Row>
    <Col span={12} offset={6}>
      <Typography.Paragraph>
        <Trans>Something went wrong checking the password reset link.</Trans>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Trans>
          Go back to the <Link to="/auth/login">login form</Link>.
        </Trans>
      </Typography.Paragraph>
    </Col>
  </Row>
);
export default ResetVerifyError;
