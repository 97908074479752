import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { ConfigProvider } from "antd";
import { createBrowserHistory } from "history";
import { en as pluralsEnglish } from "make-plural";
import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";

import AppLayout from "./components/AppLayout";
import { messages as messagesEnglish } from "./locale/en/messages";
import { Culture } from "./localization";
import store from "./store";

i18n.loadLocaleData(Culture.en, { plurals: pluralsEnglish });
i18n.load(Culture.en, messagesEnglish);
i18n.activate(Culture.en);

export const history = createBrowserHistory();

const App = () => (
  <>
    <Helmet titleTemplate={`%s - ${window.document.title}`} />
    <HistoryRouter history={history}>
      <Provider store={store}>
        <ConfigProvider>
          <I18nProvider i18n={i18n}>
            <AppLayout />
          </I18nProvider>
        </ConfigProvider>
      </Provider>
    </HistoryRouter>
  </>
);

export default memo(App);
