/*eslint-disable*/
module.exports = {
  messages: {
    "A problem occurred during activation.":
      "A problem occurred during activation.",
    About: "About",
    Activate: "Activate",
    AdAlliance: "AdAlliance",
    Administration: "Administration",
    Advertiser: "Advertiser",
    "All functions": "All functions",
    "An unknown error has occurred.": "An unknown error has occurred.",
    Authentication: "Authentication",
    "B2B API docs": "B2B API docs",
    Beta: "Beta",
    Code: "Code",
    Contributing: "Contributing",
    Core: "Core",
    "Enter your username. If you are a user of this application, you will receive an email with a link to reset your password.":
      "Enter your username. If you are a user of this application, you will receive an email with a link to reset your password.",
    "Entities and relations": "Entities and relations",
    Error: "Error",
    "Error handling": "Error handling",
    "From now on you can log on with your username and password.":
      "From now on you can log on with your username and password.",
    "General concepts": "General concepts",
    "Go back to the <0>login form</0>.": "Go back to the <0>login form</0>.",
    Invalid: "Invalid",
    "Invalid e-mail": "Invalid e-mail",
    "Is this organisation known with the operator and a purchasing/planning party?":
      "Is this organisation known with the operator and a purchasing/planning party?",
    "Known?": "Known?",
    "Log out": "Log out",
    "Logged in as: {0}": ["Logged in as: ", ["0"]],
    Login: "Login",
    "Mail sent": "Mail sent",
    "Media agency": "Media agency",
    Name: "Name",
    "New password": "New password",
    "New password (again)": "New password (again)",
    Next: "Next",
    Operators: "Operators",
    Organisations: "Organisations",
    Overig: "Overig",
    Password: "Password",
    "Password (again)": "Password (again)",
    "Password is required.": "Password is required.",
    "Password must be at least 8 characters, contain at least 1 number and upper and lower case letters":
      "Password must be at least 8 characters, contain at least 1 number and upper and lower case letters",
    "Password reset": "Password reset",
    "Passwords must be identical.": "Passwords must be identical.",
    "Production company": "Production company",
    REST: "REST",
    "Ready for use": "Ready for use",
    Reset: "Reset",
    "Reset password": "Reset password",
    "Set password": "Set password",
    "Something went wrong checking the password reset link.":
      "Something went wrong checking the password reset link.",
    "Something went wrong.": "Something went wrong.",
    "Start using MediaDogs": "Start using MediaDogs",
    Ster: "Ster",
    Talpa: "Talpa",
    "The URL you used is no longer valid. Try to <0>reset your password</0> again.":
      "The URL you used is no longer valid. Try to <0>reset your password</0> again.",
    "This link has expired. A new link will be sent to you by email.":
      "This link has expired. A new link will be sent to you by email.",
    "To the login form": "To the login form",
    Type: "Type",
    "User activation failed.": "User activation failed.",
    Username: "Username",
    "Username is required.": "Username is required.",
    "Username or password is incorrect.": "Username or password is incorrect.",
    "Verify email address": "Verify email address",
    "Your account has been successfully set up":
      "Your account has been successfully set up",
    "Your password could not be reset. An unexpected error occurred. Details: {detail}":
      [
        "Your password could not be reset. An unexpected error occurred. Details: ",
        ["detail"],
      ],
    "Your password has been reset. From now on, you can log in with your new password.":
      "Your password has been reset. From now on, you can log in with your new password.",
  },
};
