import { createSingle } from "async-lifecycle-saga";

import User from "../../models/User";
import { getCurrentUser } from "../authentication/api";
import { BaseUrlContext } from "../cells";
import { StoreModel } from "../models";

// eslint-disable-next-line import/prefer-default-export
export const usersCurrentCell = createSingle<User, StoreModel, BaseUrlContext>({
  context: ({
    application: {
      options: { baseUrl },
    },
  }) => ({
    baseUrl,
  }),
  path: ["users", "current"],
  require: { api: getCurrentUser },
});
