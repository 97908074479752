import moment from "moment";

import { TokenModel } from "./authentication/models";

export type TokenKey = "tip-refresh-token" | "tip-access-token";

export const refreshTokenKey: TokenKey = "tip-refresh-token";
export const accessTokenKey: TokenKey = "tip-access-token";

/**
 * Adds a token into the localStorage
 * @param key
 * @param token
 * @param volatile
 */
export const setToken = (
  key: TokenKey,
  token: TokenModel,
  volatile = false
): void => {
  if (volatile) {
    sessionStorage.setItem(key, JSON.stringify(token));
  } else {
    localStorage.setItem(key, JSON.stringify(token));
  }
};

/**
 * Gets a token from the localStorage. When the token is expired `undefined` will be returned
 * @param key
 * @param volatile
 */
export const getToken = (
  key: TokenKey,
  volatile = false
): TokenModel | undefined => {
  const tokenStr = volatile
    ? sessionStorage.getItem(key)
    : localStorage.getItem(key);
  if (!tokenStr) {
    return undefined;
  }

  const token = JSON.parse(tokenStr) as TokenModel;
  if (moment.utc().isAfter(moment.utc(token.expires))) {
    return undefined;
  }

  return token;
};

/**
 * Removes the token
 * @param key
 * @param volatile
 */
export const removeToken = (key: TokenKey, volatile = false): void => {
  if (volatile) {
    sessionStorage.removeItem(key);
  } else {
    localStorage.removeItem(key);
  }
};

/**
 * Clear session tokens.
 */
export const clearTokens = (): void => {
  removeToken(refreshTokenKey);
  removeToken(accessTokenKey);
};

const usernameKey = "tip-username";

/**
 * Sets the user's user name in the local storage.
 * @param username The user's user name.
 */
export const setUsername = (username: string): void => {
  localStorage.setItem(usernameKey, username.toLowerCase());
};

/**
 * Gets the user's user name from the local storage.
 */
export const getUsername = (): string | null =>
  localStorage.getItem(usernameKey);

/**
 * Removes the user's user name from the local storage.
 */
export const clearUsername = (): void => {
  localStorage.removeItem(usernameKey);
};
