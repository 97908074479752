import { Col, Row } from "antd";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import tipLogo from "../../assets/MediaDogs.jpg";
import screenforceLogo from "../../assets/screenforce-logo.png";

interface TipHeaderProps {
  menu?: ReactNode;
  userMenu?: ReactNode;
}

const TipHeader = ({ menu, userMenu }: TipHeaderProps): JSX.Element => (
  <Row>
    <Col xs={8} sm={6} md={3} flex="">
      <Link to="/">
        <img src={tipLogo} alt="MediaDogs" height="40px" />
      </Link>
    </Col>
    <Col
      xs={userMenu ? 6 : 10}
      sm={userMenu ? 13 : 15}
      md={userMenu ? 18 : 19}
      flex=""
    >
      {menu}
    </Col>
    <Col xs={6} sm={3} md={2} flex="">
      <a
        href="https://screenforce.nl/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={screenforceLogo} alt="Screenforce" height="40px" />
      </a>
    </Col>
    {userMenu && (
      <Col xs={4} sm={2} md={1} lg={1} xl={1} flex="">
        {userMenu}
      </Col>
    )}
  </Row>
);

export default TipHeader;
