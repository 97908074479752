import "swagger-ui-react/swagger-ui.css";

import "../assets/swagger-ui.css";

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import SwaggerUI from "swagger-ui-react";

import adalliance from "../assets/content/api/adalliance.yaml";
import core from "../assets/content/api/core.yaml";
import ster from "../assets/content/api/ster.yaml";
import talpa from "../assets/content/api/talpa.yaml";
import navigation from "../navigation";
import HeaderTitle from "../views/HeaderTitle";
import HtmlHead from "../views/HtmlHead";
import Main from "../views/layout/Main";

const apis: { [key: string]: string } = {
  adalliance,
  core,
  ster,
  talpa,
};

const Swagger = (): JSX.Element => {
  const { subject } = useParams() as { subject: string };
  const { pathname, hash } = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { label, parent } = navigation.find((a) => a.path === pathname)!;

  const [target, setTarget] = useState<Element | null>(null);
  useEffect((): (() => void) | undefined => {
    if (!hash) {
      return undefined;
    }

    const interval = window.setInterval(
      () => setTarget(document.querySelector(hash)),
      250
    );
    return () => window.clearInterval(interval);
  }, [hash]);
  useEffect(() => {
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  }, [target]);

  return (
    <Main wide>
      <HtmlHead title={label} />
      <HeaderTitle title={label} parent={parent} />
      <SwaggerUI url={apis[subject]} tryItOutEnabled />
    </Main>
  );
};

export default Swagger;
