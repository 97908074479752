import { AsyncResponse } from "async-lifecycle-saga";

import { BaseUrlContext } from "../../cells";
import { poster } from "../../fetch";
import {
  ActivateRequest,
  ActivateResponse,
  ActivateVerifyRequest,
} from "./models";

const emptyString = "";

export const activatePoster = (
  request: ActivateRequest
): Promise<AsyncResponse<ActivateResponse>> =>
  poster<ActivateResponse, ActivateRequest>(
    emptyString,
    "/api/user/activate",
    false,
    request
  );

export const activateVerifyPoster = (
  request: ActivateVerifyRequest,
  { baseUrl }: BaseUrlContext
): Promise<AsyncResponse<string>> =>
  poster<string, ActivateVerifyRequest>(
    baseUrl,
    "/api/user/activate/verify",
    false,
    request
  );
