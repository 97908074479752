import { AsyncResponse } from "async-lifecycle-saga";

import Organisation from "../../models/Organisation";
import { BaseUrlContext } from "../cells";
import { getter } from "../fetch";
import { OrganisationGetAllResponse } from "./models";

// eslint-disable-next-line import/prefer-default-export
export const getOrganisationsAll = (
  _request: void,
  { baseUrl }: BaseUrlContext
): Promise<AsyncResponse<Organisation[]>> =>
  getter<OrganisationGetAllResponse, Organisation[]>(
    baseUrl,
    "/api/organisation",
    true,
    ({ organisations }) => organisations
  );
