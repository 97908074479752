import { useSelector } from "react-redux";

import { StoreModel } from "../store/models";

// eslint-disable-next-line no-shadow
export enum Culture {
  en = "en",
}

export const defaultCulture = Culture.en;
export const preferredCultureKey = "preferredCulture";

export const useCulture = (): Culture => {
  const storeCulture = useSelector(
    ({
      users: {
        current: { value },
      },
    }: StoreModel) => value?.preferredCulture
  );

  return (localStorage.getItem(preferredCultureKey) ||
    storeCulture ||
    // window.tipBrowserCulture ||
    defaultCulture) as Culture;
};
