import React, { ReactNode, useCallback } from "react";
import ReactMarkdown, { Components } from "react-markdown";
import { NormalComponents } from "react-markdown/lib/complex-types";
import { Link } from "react-router-dom";
import remarkGfm from "remark-gfm";

interface Props {
  children: string;
  imgs: { [uri: string]: string };
}

const renderAnchor = ({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) =>
  href.startsWith("/api") || href.startsWith("/about") ? (
    <Link to={href}>{children}</Link>
  ) : (
    <a href={href} rel="noreferrer" target="_blank">
      {children}
    </a>
  );

const markdownComponents: Components = {
  a: renderAnchor as unknown as NormalComponents["a"],
};

const Markdown = ({ children, imgs }: Props): JSX.Element => {
  const transformImageUri = useCallback(
    (uri: string): string => imgs[uri],
    [imgs]
  );
  return (
    <ReactMarkdown
      transformImageUri={transformImageUri}
      components={markdownComponents}
      className="markdown"
      remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
