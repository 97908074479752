import { Trans } from "@lingui/macro";
import { Button, Result } from "antd";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import yeah from "../../../assets/yeah.png";

const ActivationReady = (): JSX.Element => {
  const navigate = useNavigate();
  const handleStart = useCallback(() => navigate("/"), [navigate]);

  return (
    <Result
      status="success"
      icon={<img src={yeah} alt="" />}
      title={<Trans>Your account has been successfully set up</Trans>}
      subTitle={
        <Trans>
          From now on you can log on with your username and password.
        </Trans>
      }
      extra={
        <Button type="primary" onClick={handleStart}>
          <Trans>Start using MediaDogs</Trans>
        </Button>
      }
    />
  );
};

export default ActivationReady;
